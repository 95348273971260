import {
  ERROR_NOTI_TYPE_OPTIONS as TYPE_OPTIONS,
  ERROR_NOTI_STATUS_VALUES as STATUS,
  ERROR_NOTI_STATUS_OPTIONS as STATUS_OPTIONS,
} from '@/constants/errorNoti'

export function getLocaleLabelOfErrorNotiType(errorNotiType) {
  return this.$t(TYPE_OPTIONS.find(item => item.value === errorNotiType).label)
}

export function getLocaleLabelOfErrorNotiStatus(errorNotiStatus) {
  return this.$t(
    STATUS_OPTIONS.find(item => item.value === errorNotiStatus)?.label,
  )
}

/* Conditions to enable error noti actions */
const canViewDetail = status => status === STATUS.refuseToSign

const canPreviewOnly = status => [
  STATUS.sign,
  STATUS.waitingToIssue,
  STATUS.issue,
  STATUS.refuseToIssue,
].includes(status)

const canDelete = status => status === STATUS.draft

const canUpdateOrSendToSign = status => status === STATUS.draft || status === STATUS.refuseToSign

const canSignOrRefuseSign = status => status === STATUS.waitingToSign

export const actions = {
  canViewDetail, // có thể XEM CHI TIẾT
  // canPreview: true, // có thể XEM TRƯỚC với bất kỳ error noti status nào
  canPreviewOnly, // có có mỗi action XEM TRƯỚC
  canDelete, // có thể XOÁ BỎ (xoá khỏi DB, số thông báo xoá bỏ có thể được dùng lại)
  canUpdateOrSendToSign, // có thể CẬP NHẬT hoặc GỬI KÝ
  canSignOrRefuseSign, // có thể KÝ hoặc TỪ CHỐI KÝ
}
/* !Conditions to enable error noti actions */
