var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "popup-invoice-info",
      "size": "xl",
      "centered": "",
      "header-bg-variant": "light-info",
      "body-class": "p-1",
      "footer-class": "justify-content-between",
      "title-class": "h2 font-weight-bolder text-dark",
      "ok-title": _vm.$t('errorNoti.close'),
      "ok-only": ""
    }
  }, [_c('b-table', {
    attrs: {
      "responsive": "",
      "show-empty": "",
      "primary-key": "id",
      "table-class": "table-invoice-edit-gs border-bottom",
      "items": _vm.invoiceData,
      "fields": _vm.tableColumnsAdd,
      "empty-text": _vm.$t('noRecordFund')
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumnsAdd, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(_ref) {
          var label = _ref.label;
          return [_c('div', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: column.key != 'action',
              expression: "column.key != 'action'"
            }],
            key: column.label,
            staticClass: "text-dark text-nowrap text-center"
          }, [_vm._v(" " + _vm._s(_vm.$t("errorNoti.gsColumns.".concat(label))) + " ")])];
        }
      };
    }), {
      key: "cell(orderNo)",
      fn: function fn(_ref2) {
        var index = _ref2.index;
        return [_c('div', {
          staticClass: "w-100 text-center"
        }, [_vm._v(" " + _vm._s(index + 1) + " ")])];
      }
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }