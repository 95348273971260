<template>
  <b-modal
    id="popup-invoice-info"
    size="xl"
    centered
    header-bg-variant="light-info"
    body-class="p-1"
    footer-class="justify-content-between"
    title-class="h2 font-weight-bolder text-dark"
    :ok-title="$t('errorNoti.close')"
    ok-only
  >
    <b-table
      responsive
      show-empty
      primary-key="id"
      table-class="table-invoice-edit-gs border-bottom"
      :items="invoiceData"
      :fields="tableColumnsAdd"
      :empty-text="$t('noRecordFund')"
    >
      <!-- ANCHOR Table Header -->
      <template
        v-for="column in tableColumnsAdd"
        #[`head(${column.key})`]="{ label }"
      >
        <div
          v-show="column.key != 'action'"
          :key="column.label"
          class="text-dark text-nowrap text-center"
        >
          {{ $t(`errorNoti.gsColumns.${label}`) }}
        </div>
      </template>

      <!-- ANCHOR Column orderNo -->
      <template #cell(orderNo)="{ index }">
        <div class="w-100 text-center">
          {{ index + 1 }}
        </div>
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import {
  BModal,
  BTable,
} from 'bootstrap-vue'
import {
  ref,
} from '@vue/composition-api'

import useErrorNotiAddHandle from '../error-noti-add/useErrorNotiAddHandle'

export default {
  components: {
    BModal,
    BTable,
  },

  props: {
    invoiceData: {
      type: Array,
      default: null,
    },
  },

  setup() {
    const selectionCount = ref(0)

    function onSelectInvoices() {
      if (selectionCount.value) {
        const payload = {
          selectionCount: selectionCount.value,
          selectedInvoices: this.$refs.invoiceList.selectedInvoices,
        }
        this.$emit('selectInvoices', payload)
      }
    }

    const {
      tableColumnsAdd,
    } = useErrorNotiAddHandle()

    return {
      selectionCount,

      onSelectInvoices,

      tableColumnsAdd,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

// Switch color
// .custom-switch .custom-control-input:not(:checked) ~ .custom-control-label:before {
//     background-color: #ea5455; // same danger color of bootstap
// }
</style>
