var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PopupInvoiceInfo', {
    attrs: {
      "invoice-data": _vm.invoiceData
    }
  }), _c('ErrorNotiListFilters', {
    ref: "errorNotiListFilters",
    attrs: {
      "search-text": _vm.searchText
    },
    on: {
      "update:searchText": function updateSearchText($event) {
        _vm.searchText = $event;
      },
      "update:search-text": function updateSearchText($event) {
        _vm.searchText = $event;
      },
      "refetch-data-with-filters": _vm.refetchData,
      "refetch-data-without-filters": _vm.clearFilter
    }
  }), _c('b-card', {
    staticStyle: {
      "max-height": "70vh"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('div', [_c('b-row', {
    staticClass: "m-1"
  }, [_c('b-col', {
    staticClass: "px-0 mr-md-1",
    attrs: {
      "cols": "4",
      "md": "auto"
    }
  }, [_c('v-select', {
    staticClass: "per-page-selector d-inline-block mx-50",
    attrs: {
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "options": _vm.sizePerPageOptions,
      "clearable": false
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }]),
    model: {
      value: _vm.sizePerPage,
      callback: function callback($$v) {
        _vm.sizePerPage = $$v;
      },
      expression: "sizePerPage"
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex justify-content-end px-50",
    attrs: {
      "cols": "8",
      "md": "auto",
      "order-md": "3"
    }
  }, [_c('div', {
    staticClass: "d-none d-lg-flex"
  }, [_c('b-button', {
    staticClass: "mr-1 px-lg-1 px-md-75",
    staticStyle: {
      "min-width": "120px"
    },
    attrs: {
      "variant": "info",
      "to": {
        name: 'apps-error-noti-add'
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "PlusSquareIcon",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('errorNoti.create')) + " ")], 1)]), _c('b-button', {
    staticClass: "px-lg-1 px-md-75",
    staticStyle: {
      "min-width": "120px"
    },
    attrs: {
      "variant": "info"
    },
    on: {
      "click": function click($event) {
        return _vm.confirmExport();
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "DownloadIcon",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('export')) + " ")], 1)])], 1), _c('div', {
    staticClass: "d-block d-lg-none"
  }, [_c('b-dropdown', {
    staticClass: "m-lg-2",
    attrs: {
      "variant": "info",
      "boundary": "window",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "MenuIcon",
            "size": "16"
          }
        }), _c('span', {
          staticClass: "pl-50"
        }, [_vm._v(_vm._s(_vm.$t('moreDropdownTitle')))])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "to": {
        name: 'apps-error-noti-add'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('errorNoti.moreDropdown.add')) + " ")]), _c('b-dropdown-item', {
    on: {
      "click": function click($event) {
        return _vm.confirmExport();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('errorNoti.moreDropdown.export')) + " ")])], 1)], 1)]), _c('b-col', {
    staticClass: "mt-1 mt-md-0 px-50 flex-grow-1",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-input-group', {
    staticClass: "w-100 mr-md-1",
    attrs: {
      "size": "md"
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center border rounded-left px-1 bg-light-info"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "SearchIcon",
            "size": "20"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-form-input', {
    attrs: {
      "type": "search",
      "placeholder": _vm.$t('errorNoti.phSearch'),
      "reduce": function reduce(val) {
        return val.value;
      },
      "debounce": "500",
      "trim": ""
    },
    on: {
      "input": function input($event) {
        return _vm.onDebounceSearch($event, _vm.onRefetchData);
      }
    },
    model: {
      value: _vm.searchText,
      callback: function callback($$v) {
        _vm.searchText = $$v;
      },
      expression: "searchText"
    }
  })], 1)], 1)])], 1), _c('b-row', {
    staticClass: "font-weight-bold text-white mx-0 py-25 py-md-75",
    staticStyle: {
      "background-color": "#92CBFD"
    }
  }, [_c('b-col', {
    staticClass: "d-flex align-items-center px-0 mx-1 mx-md-2",
    attrs: {
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "d-inline-block"
  }, [_vm.notSelect ? [_vm._v(" " + _vm._s(_vm.$tc('errorNoti.selectionCount', 0)) + " ")] : _vm.selectionCount === 1 ? [_vm._v(" " + _vm._s(_vm.$tc('errorNoti.selectionCount', 1)) + " ")] : [_vm._v(" " + _vm._s(_vm.$tc('errorNoti.selectionCount', _vm.selectionCount, {
    count: _vm.selectionCount
  })) + " ")]], 2)]), _c('b-col', {
    staticClass: "d-flex flex-grow-1 justify-content-end align-items-center px-0 mx-1 mx-md-2",
    attrs: {
      "md": "auto"
    }
  }, [!_vm.roleMama ? _c('span', {
    class: _vm.notSelect ? 'text-secondary cursor-not-allowed' : 'hover-text-primary cursor-pointer'
  }, [_vm._v(" " + _vm._s(_vm.$t('errorNoti.btn.sendToSign')) + " ")]) : _vm._e(), _vm.roleMama ? [_c('span', {
    staticClass: "ml-1 ml-md-2",
    class: _vm.notSelect ? 'text-secondary cursor-not-allowed' : 'hover-text-primary cursor-pointer'
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.btn.sign')) + " ")]), _c('span', {
    staticClass: "ml-50 ml-md-1",
    class: _vm.notSelect ? 'text-secondary cursor-not-allowed' : 'hover-text-primary cursor-pointer'
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.btn.refuseSign')) + " ")])] : _vm._e()], 2)], 1)], 1), _c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm",
      "no-fade": "",
      "variant": "transparent",
      "opacity": 0.5
    }
  }, [_c('b-table', {
    ref: "refErrorNotiListTable",
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "50vh"
    },
    attrs: {
      "sticky-header": "",
      "items": _vm.fetchErrorNoti,
      "responsive": "",
      "bordered": "",
      "fields": _vm.tableColumns,
      "primary-key": "id",
      "table-class": "table-error-noti-list",
      "show-empty": "",
      "empty-text": _vm.$t('noRecordFund'),
      "sort-desc": _vm.isSortDirDesc,
      "sort-by": _vm.sortBy,
      "no-border-collapse": ""
    },
    on: {
      "update:sortDesc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('div', {
            key: column.label,
            staticClass: "text-dark"
          }, [_c('span', {
            staticClass: "text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("errorNoti.columns.".concat(data.label))) + " ")])])];
        }
      };
    }), {
      key: "cell(checkbox)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('b-form-checkbox', {
          staticClass: "mr-0 ml-50 mt-50",
          attrs: {
            "name": "check-box",
            "inline": "",
            "disabled": _vm.actions.canPreviewOnly(item.status),
            "checked": _vm.isChecked(item.id)
          },
          on: {
            "change": function change($event) {
              return _vm.chooseItem(item.id);
            }
          }
        })];
      }
    }, {
      key: "cell(invoices)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "text-right text-primary cursor-pointer font-weight-bold",
          on: {
            "click": function click($event) {
              return _vm.openPopupInvoices(item.invoices);
            }
          }
        }, [_vm._v(" " + _vm._s(item.invoices.length) + " ")])];
      }
    }, {
      key: "cell(taAcceptDate)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "mb-0 text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.dateTime(item.taAcceptDate)) + " ")])];
      }
    }, {
      key: "cell(status)",
      fn: function fn(_ref4) {
        var status = _ref4.item.status;
        return [_c('b-badge', {
          staticClass: "badge-glow",
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" " + _vm._s(_vm.getLocaleLabelOfErrorNotiStatus(status)) + " ")])];
      }
    }, {
      key: "cell(createdAt)",
      fn: function fn(_ref5) {
        var _ref5$item = _ref5.item,
          createdAt = _ref5$item.createdAt,
          createdBy = _ref5$item.createdBy;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.dateTime(createdAt)) + " ")]), _c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.getLocaleFullName(createdBy)) + " ")])];
      }
    }, {
      key: "cell(updatedAt)",
      fn: function fn(_ref6) {
        var _ref6$item = _ref6.item,
          updatedAt = _ref6$item.updatedAt,
          updatedBy = _ref6$item.updatedBy;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.dateTime(updatedAt)) + " ")]), _c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.getLocaleFullName(updatedBy)) + " ")])];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('div', {
          staticClass: " text-right text-nowrap"
        }, [_c('b-link', {
          staticClass: "text-info"
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          style: _vm.actions.canPreviewOnly(item.status) ? 'margin-right: 25px;' : 'margin-right: 7px;',
          attrs: {
            "id": "err-noti-row-".concat(item.id, "-preview-icon"),
            "icon": "EyeIcon",
            "size": "16"
          }
        }), _c('b-tooltip', {
          attrs: {
            "title": _vm.$t('errorNoti.preview'),
            "target": "err-noti-row-".concat(item.id, "-preview-icon")
          }
        })], 1), !_vm.actions.canPreviewOnly(item.status) ? _c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "p-0",
            "no-caret": "",
            "right": _vm.$store.state.appConfig.isRTL
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "align-middle text-body",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_vm.actions.canViewDetail(item.status) ? _c('b-dropdown-item', {
          attrs: {
            "to": {
              name: 'apps-error-noti-detail',
              params: {
                id: item.id
              }
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "BookOpenIcon"
          }
        }), _c('span', {
          staticClass: "align-middle ml-50"
        }, [_vm._v(_vm._s(_vm.$t('Detail')))])], 1) : _vm._e(), _vm.actions.canUpdateOrSendToSign(item.status) ? _c('b-dropdown-item', {
          attrs: {
            "to": {
              name: 'apps-error-noti-update',
              params: {
                id: item.id
              }
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        }), _c('span', {
          staticClass: "align-middle ml-50"
        }, [_vm._v(_vm._s(_vm.$t('update')))])], 1) : _vm._e(), _vm.actions.canUpdateOrSendToSign(item.status) ? _c('b-dropdown-item', [_c('feather-icon', {
          attrs: {
            "icon": "ZapIcon"
          }
        }), _c('span', {
          staticClass: "align-middle ml-50"
        }, [_vm._v(_vm._s(_vm.$t('errorNoti.btn.sendToSign')))])], 1) : _vm._e(), _vm.actions.canSignOrRefuseSign(item.status) ? _c('b-dropdown-item', [_c('feather-icon', {
          attrs: {
            "icon": "ZapIcon"
          }
        }), _c('span', {
          staticClass: "align-middle ml-50"
        }, [_vm._v(_vm._s(_vm.$t('invoice.btn.sign')))])], 1) : _vm._e(), _vm.actions.canSignOrRefuseSign(item.status) ? _c('b-dropdown-item', [_c('feather-icon', {
          attrs: {
            "icon": "ZapOffIcon"
          }
        }), _c('span', {
          staticClass: "align-middle ml-50"
        }, [_vm._v(_vm._s(_vm.$t('invoice.btn.refuseSign')))])], 1) : _vm._e(), _vm.actions.canDelete(item.status) ? _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.confirmDelete(item.id);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', {
          staticClass: "align-middle ml-50"
        }, [_vm._v(_vm._s(_vm.$t('errorNoti.btn.delete')))])], 1) : _vm._e()], 1) : _vm._e()], 1)];
      }
    }], null, true)
  })], 1), _c('div', {
    staticClass: "mx-2 mb-1"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('span', {
    staticClass: "text-muted"
  }, [_vm._v(" " + _vm._s(_vm.dataMeta.from) + " " + _vm._s(_vm.$t('paginationText.to')) + " " + _vm._s(_vm.dataMeta.to) + " " + _vm._s(_vm.$t('paginationText.outOf')) + " " + _vm._s(_vm.dataMeta.of) + " " + _vm._s(_vm.$t('paginationText.items')) + " ")])]), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('b-pagination', {
    staticClass: "mb-0 mt-1 mt-sm-0",
    attrs: {
      "total-rows": _vm.totalItems,
      "per-page": _vm.sizePerPage,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }