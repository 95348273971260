<template>
  <b-card
    no-body
    class="mb-1"
  >
    <b-card-header class="py-1">
      <h4 class="card-title font-weight-bolder">
        {{ $t('filters') }}
      </h4>
      <div class="d-flex align-items-center justify-content-end">
        <!-- ANCHOR Button Search -->
        <b-button
          style="min-width: 120px"
          variant="warning"
          class="mr-1"
          @click="onUpdateFilters"
        >
          <span class="text-nowrap">
            <feather-icon
              class="cursor-pointer"
              icon="SearchIcon"
              size="16"
            />
            {{ $t('search') }}
          </span>
        </b-button>

        <!-- ANCHOR Button Clear Search Filters -->
        <b-button
          style="min-width: 120px"
          variant="danger"
          @click="onClearFilters"
        >
          <span class="text-nowrap">
            <feather-icon
              class="cursor-pointer"
              icon="XOctagonIcon"
              size="16"
            />
            {{ $t('clear') }}
          </span>
        </b-button>
      </div>
    </b-card-header>

    <b-card-body class="py-0">
      <b-row>
        <!-- ANCHOR Filter: Date Option -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label-class="h5"
            :label="$t('invoice.date')"
          >
            <v-select
              v-model="dateOption"
              style="font-size: 1rem;"
              clearable
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :placeholder="$t('invoice.phSelectDateOption')"
              :options="DATE_OPTIONS"
              :reduce="val => val.value"
            >
              <template #option="data">
                <span>
                  {{ $t(data.label) }}
                </span>
              </template>
              <template #selected-option="data">
                <span>
                  {{ $t(data.label) }}
                </span>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <!-- ANCHOR Filter: Start Date -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label-class="h5"
            :label="$t('invoice.filters.from')"
          >
            <b-form-datepicker
              v-model="startDate"
              :today-button="!endDate"
              reset-button
              close-button
              :locale="$i18n.locale"
              :max="maxStartDate"
              :placeholder="$t('invoice.phSelectDate')"
              :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
            />
          </b-form-group>
        </b-col>

        <!-- ANCHOR Filter: End Date -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label-class="h5"
            :label="$t('invoice.filters.to')"
          >
            <b-form-datepicker
              v-model="endDate"
              today-button
              reset-button
              close-button
              :locale="$i18n.locale"
              :min="minEndDate"
              :max="today"
              :placeholder="$t('invoice.phSelectDate')"
              :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <!-- ANCHOR Filter: Error Noti Number -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label-class="h5"
            :label="$t('errorNoti.errorNotiNo')"
          >
            <b-form-input
              v-model.trim="no"
              :placeholder="$t('errorNoti.phErrorNotiNo')"
            />
          </b-form-group>
        </b-col>

        <!-- ANCHOR Filter: Error Noti Type -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label-class="h5"
            :label="$t('errorNoti.errorNotiType')"
          >
            <v-select
              v-model="type"
              style="font-size: 1rem;"
              clearable
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :placeholder="$t('errorNoti.phSelectNotiType')"
              :options="TYPE_OPTIONS"
              :reduce="val => val.value"
            >
              <template #option="data">
                <span>
                  {{ $t(data.label) }}
                </span>
              </template>
              <template #selected-option="data">
                <span>
                  {{ $t(data.label) }}
                </span>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <!-- ANCHOR Filter: Error Noti Status -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label-class="h5"
            :label="$t('errorNoti.notiStatus')"
          >
            <v-select
              v-model="status"
              style="font-size: 1rem;"
              clearable
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :placeholder="$t('errorNoti.phSelectStatus')"
              :options="STATUS_OPTIONS"
              :reduce="val => val.value"
            >
              <template #option="data">
                <span>
                  {{ $t(data.label) }}
                </span>
              </template>
              <template #selected-option="data">
                <span>
                  {{ $t(data.label) }}
                </span>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  ref,
  computed,
} from '@vue/composition-api'
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BFormGroup, BFormInput, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import {
  DATE_OPTIONS,
  ERROR_NOTI_TYPE_OPTIONS as TYPE_OPTIONS,
  ERROR_NOTI_STATUS_OPTIONS as STATUS_OPTIONS,
} from '@/constants/errorNoti'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormDatepicker,

    vSelect,
  },

  props: {
    searchText: {
      type: String,
      default: '',
    },
  },

  setup(props, { emit }) {
    const dateOption = ref(DATE_OPTIONS[0].value)
    const startDate = ref('')
    const endDate = ref('')
    const no = ref('')
    const type = ref('')
    const status = ref('')

    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

    const maxStartDate = computed(() => (endDate.value ? endDate.value : today))
    const minEndDate = computed(() => (startDate.value ? startDate.value : undefined))

    const onClearFilters = () => {
      dateOption.value = ''
      startDate.value = ''
      endDate.value = ''
      no.value = ''
      type.value = ''
      status.value = ''
      emit('update:searchText', '')
      emit('refetch-data-without-filters')
    }

    const onUpdateFilters = () => {
      const filtersData = {
        searchDateBy: dateOption.value || undefined,
        dateFrom: startDate.value || undefined,
        dateTo: endDate.value || undefined,
        no: no.value || undefined,
        type: type.value || undefined,
        status: status.value || undefined,
      }
      emit('refetch-data-with-filters', filtersData)
    }

    return {
      // from imports
      DATE_OPTIONS,
      STATUS_OPTIONS,
      TYPE_OPTIONS,

      // from setup
      dateOption,
      startDate,
      endDate,
      no,
      type,
      status,

      today,
      maxStartDate,
      minEndDate,

      onClearFilters,
      onUpdateFilters,
    }
  },
}
</script>
